import { Box, Button, CircularProgress } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorDialog, EstablishmentAppBar } from '../../shared/components';
import { Loading } from '../../shared/components/Loading';
import { QuantityManager } from '../../shared/components/QuantityManager';
import { ReloadScreen } from '../../shared/components/ReloadScreen';
import { useEstablishment } from '../../shared/contexts';
import { useBag } from '../../shared/contexts/BagContext';
import { OrderItem } from '../../shared/entities';
import { useDidUpdate } from '../../shared/hooks/did_update';
import { usePizza } from './PizzaContext';
import { Additionals } from './components/Additionals';
import { PizzaChart, PizzaChartHandle } from './components/PizzaChart';
import { PizzaSizes } from './components/PizzaSizes';
import { ObservationInput } from '../../shared/components/ObservationInput';

type ErrorDialogProps = {
	error: string;
	close?: () => void;
};

export function PizzaPage() {
	const navigate = useNavigate();
	const { establishment, config } = useEstablishment().state;
	const pizzaChartRef = useRef<PizzaChartHandle>(null);
	const { addItem } = useBag();
	const [dialogError, setDialogError] = useState<ErrorDialogProps | null>(null);
	const {
		isLoading,
		error,
		quantity,
		totalPrice,
		size,
		product,
		isLoadingProduct,
		observations,
	} = usePizza().state;
	const {
		loadPizza,
		incrementQuantity,
		decrementQuantity,
		selectedAdditionals,
		limitFlavorsReached,
		areRequiredAdditionalsSelected,
		isCrustSelected,
		onObservationChange,
	} = usePizza();

	function addProductToBag() {
		if (size == null) {
			return setDialogError({ error: 'Você precisa selecionar um tamanho' });
		}
		if (!limitFlavorsReached()) {
			return setDialogError({
				error: `Você precisa selecionar 
				${size!.flavorsQtt === 1 ? '1 sabor' : `${size.flavorsQtt!} sabores`}`,
				close: () => scrollToAdditionalGroup('additional-group-header-flavor'),
			});
		}
		if (!isCrustSelected()) {
			return setDialogError({
				error: 'Você precisa selecionar uma borda',
				close: () => scrollToAdditionalGroup('additional-group-header-crust'),
			});
		}
		if (!areRequiredAdditionalsSelected()) {
			return setDialogError({
				error: 'Selecione os adicionais obrigatórios',
				close: () =>
					scrollToAdditionalGroup('additional-group-header-additional', {
						requiredGroup: true,
					}),
			});
		}
		const item = new OrderItem(
			product!.id,
			product!.name,
			product!.price,
			quantity,
			observations,
			establishment.isSelfService ? config.tipPercent : 0,
			selectedAdditionals(),
			totalPrice,
			true,
			size.id
		);
		addItem(item);
		navigate(-1);
	}

	useEffect(() => {
		loadPizza();
	}, []);

	const scrollToAdditionalGroup = (
		className: string,
		{ requiredGroup = false } = {}
	) => {
		const targetElements = document.getElementsByClassName(className);
		let targetElement;
		if (requiredGroup) {
			for (let i = 0; i < targetElements.length; i++) {
				const element = targetElements[i];
				if (element.classList.contains('additional-group-required')) {
					targetElement = element;
					break;
				}
			}
		} else {
			targetElement = targetElements[0];
		}
		const position = targetElement?.getBoundingClientRect();
		if (position) {
			window.scrollBy({ behavior: 'smooth', top: position.top - 250 });
		}
	};

	useDidUpdate(() => {
		if (size) {
			pizzaChartRef.current?.scrollToPizzaChart();
		}
	}, [size]);

	return (
		<>
			<EstablishmentAppBar />
			{(() => {
				if (isLoading) {
					return <Loading />;
				} else if (error != null) {
					return (
						<ReloadScreen
							message={error.message}
							onReload={() => loadPizza()}
						/>
					);
				}

				return (
					<div style={{ marginBottom: '100px' }}>
						<div style={{ padding: '20px' }}>
							<PizzaSizes />

							{isLoadingProduct && (
								<Box
									display="flex"
									justifyContent="center"
									alignItems="center"
									marginTop="32px"
								>
									<CircularProgress disableShrink />
								</Box>
							)}

							{size && product && <PizzaChart ref={pizzaChartRef} />}
							{size && product && <Additionals />}
							{size && product &&
								<div style={{ marginTop: '60px' }}>
									<ObservationInput value={observations} onChange={onObservationChange} />
								</div>}
						</div>

						<Box
							sx={{
								position: 'fixed',
								bottom: 0,
								left: 0,
								right: 0,
							}}
							height="60px"
							bgcolor="grey.100"
							display="flex"
							alignItems="center"
							justifyContent="space-between"
							padding="0 20px"
						>
							<QuantityManager
								quantity={quantity}
								onIncrement={incrementQuantity}
								onDecrement={decrementQuantity}
								decrementEnabled={quantity > 1}
							/>
							<Button
								variant="contained"
								disableElevation
								onClick={addProductToBag}
							>
								Adicionar | {totalPrice.toCurrency()}
							</Button>
						</Box>
					</div>
				);
			})()}
			<ErrorDialog
				error={dialogError?.error}
				open={dialogError != null}
				close={() => {
					dialogError?.close?.();
					setDialogError(null);
				}}
			/>
		</>
	);
}
